<template>
  <div style="width:100%; height: 100%;overflow:scroll;">
    <div class="ls-box">
      <span class="v-form-label">预定时间:</span>
      <div>
        <el-date-picker
          size="mini"
          v-model="dates"
          type="daterange"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
      </div>
      <span class="v-form-label">姓名 / 电话:</span>
      <div>
        <el-input
          placeholder="姓名 / 电话"
          size="mini"
          v-model="condition"
          clearable
        >
        </el-input>
      </div>
      <el-button style="margin-left: 18px" size="mini" @click="chaxun()">查询</el-button>
      <el-button style="margin-left: 18px" size="mini" @click="qk()">全款预定</el-button>
      <el-button style="margin-left: 18px" size="mini" @click="dj()">定金预定</el-button>
    </div>
    <div class="ls-nr">
      <div class="ls-titel">
        <span style="width: 12%;">日期</span>
        <span style="width: 12%;">客户姓名</span>
        <span style="width: 12%;">性别</span>
        <span style="width: 10%;">手机号</span>
        <span style="width: 17%">内容</span>
        <span style="width: 11%">原价</span>
        <span style="width: 18%;">支付金额</span>
        <span style="width: 12%;">操作</span>
      </div>
      <div class="ls-items">
        <div class="ls-item" v-if="list.length>0" v-for="(item,index) in list" :class="{'active':index%2!=0}">
          <div class="lsi-nr">
            <div class="lsi-dv" style="width: 12%">
              <span>
                {{item.createTime}}
              </span>
            </div>
            <div class="lsi-dv" style="width: 12%;">
              <span>{{item.memberName}}</span>
            </div>
            <div class="lsi-dv" style="width: 12%;">
              <span>{{item.sex==1?'男':'女'}}</span>
            </div>
            <div class="lsi-dv" style="width: 10%;">
              <span>{{item.phone}}</span>
            </div>
            <div class="lsi-hb" style="width: 28%;">
              <div class="lsih-sp" v-for="(it,index) in item.orderInfoVoList">
                <span class="lspan1" style="border-right: #C4C4C4 solid 1px;">
                  <span class="sp-type" v-if="it.commodityType==0">
                    <span v-if="it.commoditySign==0">(项目)</span>
                    <span v-if="it.commoditySign==1||it.commoditySign==4">(套餐消耗)</span>
                    <span v-if="it.commoditySign==2">(会员卡消耗)</span>
                    <span v-if="it.commoditySign==3">(年卡消耗)</span>
                    <span v-if="it.commoditySign==5||it.commoditySign==10">(商城消耗)</span>
                  </span>
                  <span class="sp-type" v-if="it.commodityType==1">(卖品)</span>
                  <span class="sp-type" v-if="it.commodityType==2">(套餐)</span>
                  <span class="sp-type" v-if="it.commodityType==3">(开卡)</span>
                  <span class="sp-type" v-if="it.commodityType==4">(充值)</span>
                  <span class="sp-type" v-if="it.commodityType==5">(品项)</span>
                  <span class="sp-type" v-if="it.commodityType==10">(成品)</span>
                  <span style="text-align: left;display: flex;flex-direction: column">
                    <span>{{it.commodityName}}</span>
                  </span>
                </span>
                <span class="lspan2">
                  <span style="display: flex;flex-direction: column;justify-content: center;align-items: center">
                    <span>¥ {{it.price}}</span>
                  </span>
                </span>
              </div>
            </div>
            <div class="lsi-hb" style="width: 18%;">
              <span class="lsih-sp" v-for="(it,index) in item.orderInfoVoList">
                <span class="jiage" v-if="item.depositStatus==0">{{it.dj}} (全款)</span>
                <span class="jiage" v-if="item.depositStatus==1">
                  <span style="margin-right: 3px;width: 50%">{{it.dj}} (定金)</span>
                  <span style="width: 50%">{{it.wk}} (尾款)</span>
                </span>
                   <span class="jiage" v-if="item.depositStatus==2">
                  <span style="margin-right: 3px;width: 50%">{{it.wk}} (补尾款)</span>
                </span>
              </span>
            </div>
            <div class="lsi-btn" style="width: 12%;border-right: #C4C4C4 solid 0px;">
              <span class="odbtn1" @click="toWk(item)" v-if="item.depositStatus==1">补尾款</span>
              <span class="odbtn" @click="tomblq(item)" v-if="item.depositStatus!=1">客户领取</span>
            </div>
          </div>
        </div>
      </div>
      <div class="pagination">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :pager-count="7"
          layout="total, prev, pager, next"
          :total="pagination.count"
          :page-count="pagination.pageCount"
          :current-page="pagination.pageNumber"
        ></el-pagination>
      </div>
    </div>
    <Qklq v-if="xqVisible" :xqVisible="xqVisible" :id="depositId" :mbid="memberId" @closeXQ="closeXQ"/>
    <Wk v-if="wkVisible" :xqVisible="wkVisible" :id="depositId" :mbid="memberId" @closeWk="closeWk"/>
  </div>
</template>

<script>

  import {getSwName, getToken} from "../../utils/auth";
  import Qklq from './qklq'
  import Wk from './wk'

  export default {
    components: {
      Qklq,
      Wk
    },
    name: 'deposit',
    data() {
      return {
        swName: '',
        condition: '',
        dates: [],
        list: [],
        pagination: {
          count: 0,
          pageNumber: 0,
          pageSize: 0,
          pages: 1
        },

        depositId: '',
        memberId: '',
        xqVisible: false,
        wkVisible: false,
      }
    },
    async created() {
      this.swName = getSwName()
      this.getSwDepositList()
    },
    methods: {
      toMbDetails(memberId) {
        this.$router.push(`/customers/memberInfo?memberId=` + memberId);
      },
      handleCurrentChange(page) {
        this.getSwDepositList(page)
      },

      closeXQ() {
        this.xqVisible = false
        this.getSwDepositList()
      },
      closeWk() {
        this.wkVisible = false
        this.getSwDepositList()
      },

      chaxun() {
        this.getSwDepositList()
      },
      qk() {
        this.$router.push('/deposits/qkkd');
      },
      dj() {
        this.$router.push('/deposits/djkd');
      },
      toWk(item) {
        this.depositId = item.depositId
        this.memberId = item.memberId
        this.wkVisible = true
      },
      tomblq(item) {
        this.depositId = item.depositId
        this.memberId = item.memberId
        this.xqVisible = true
      },
      //门店流水
      async getSwDepositList(page) {
        let currentPage = page || 1;
        let staDate = "";
        let endDate = "";
        if (this.dates.length > 0) {
          staDate = this.dates[0];
          endDate = this.dates[1];
        }
        let data = {
          token: getToken(),
          condition: this.condition,
          staDate: staDate,
          endDate: endDate,
          currentPage
        }
        this.loading = true
        let res = await this.$get("/sw/getSwDepositList", data)
        this.loading = false
        let list = res.dataSet.list;
        this.list = list;
        this.pagination = {
          count: res.dataSet.count,
          pageNumber: res.dataSet.pageNumber,
          pageSize: res.dataSet.pageSize,
          pageCount: res.dataSet.pages
        }
      },
    },
  }

</script>


<style scoped>
  .ls {
    padding: 0 15px;
    background: #fff;
    border-bottom: #e4e4e4 solid 1px;
  }

  .ls .eti {
    display: block;
    color: #1e1e1e;
    font-size: 14px;
    font-weight: 700;
    line-height: 45px;
    text-align: center;
  }

  .ls-nr {
    width: 98%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    font-size: 10px;
    margin-top: 20px;
  }

  .ls-titel {
    display: flex;
    flex-direction: row;
    background-color: #EEEEEE;
    margin-bottom: 16px;
  }

  .ls-titel span {
    text-align: center;
    line-height: 32px;
  }

  .ls-items {
    width: 100%;
  }

  .ls-item {
    margin-bottom: 30px;
  }

  .lsi-titel {
    display: flex;
    flex-direction: row;
    line-height: 30px;
    padding: 0px 25px;
    align-items: center;
    border: #C4C4C4 solid 1px;
  }

  .lsi-od {
    width: 180px;
  }

  .lsi-xq {
    width: 80%;
    text-align: right;
    height: 20px;
  }

  .lsi-dv {
    display: flex;
    justify-items: center;
    align-items: center;
    border-right: #C4C4C4 solid 1px;
    /*border-bottom: #C4C4C4 solid 1px;*/
    text-align: center;
  }

  .lsi-dv span {
    width: 100%;
  }

  .lsi-nr {
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    font-size: 10px;
    border: #C4C4C4 solid 1px;
  }

  .lsi-nr .lsi-hb {
    width: 26%;
    border-right: #C4C4C4 solid 1px;
  }

  .lsih-sp {
    display: block;
    line-height: 32px;
  }

  .lsih-sp .lspan1 {
    display: inline-block;
    width: 60%;
  }

  .lsih-sp .lspan2 {
    display: inline-block;
    width: 39%;
  }

  .lsi-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-right: #C4C4C4 solid 1px;
    text-align: center;
  }

  .jiage {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .lspan2-cost {
    position: relative;
    top: 10px;
  }

  .sp-type {
    display: inline-block;
    float: left;
    padding-left: 15%;
    margin-right: 18px;
    color: #ff4d51;
  }

  .ls-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 12px 8px;
  }

  .pagination {
    margin: 12px 0 25px 0;
    text-align: right;
  }

  .active {
    background-color: #F6F6F6;
  }

  .ob-sp {
    height: 23px;
    line-height: 23px;
    text-align: right;
  }

  .ob-val {
    display: inline-block;
    width: 120px;
    text-align: left;
    padding-left: 3px;
  }

  .odbtn {
    color: #ee0a24;
    cursor: pointer;
    height: 20px;
    line-height: 20px;
    margin: 0 12px;
  }

  .odbtn1 {
    color: #2b94ff;
    cursor: pointer;
    height: 20px;
    line-height: 20px;
    margin: 0 12px;
  }
</style>
