<template>
  <div>
    <el-dialog v-if="xqVisible" :close-on-click-modal="false" title="全款开单领取" width="60%" :visible.sync="xqVisible"
               :before-close="closeEvent">
      <div style="height: 600px;display: flex;flex-direction: row">
        <div style="width: 100%;">
          <div class="lqbt">客户信息</div>
          <div style="border: #dddddd solid 1px">
            <div class="mbinfo">
              <span class="bttt">姓名</span>
              <span class="bttt">手机</span>
              <span class="bttt">性别</span>
            </div>
            <div class="mbinfo" style="margin-bottom: 10px">
              <span class="bttt1">{{memberName}}</span>
              <span class="bttt1">{{phone}}</span>
              <span class="bttt1" v-if="sex==1">男</span>
              <span class="bttt1" v-if="sex==2">女</span>
            </div>
          </div>
          <div class="lqbt">商品信息</div>
          <div >
            <el-table
              :data="ditems"
              border
              style="width: 100%">
              <el-table-column prop="commodityName" label="商品名称" width="180"/>
              <el-table-column prop="price" label="商品价格"/>
              <el-table-column prop="dj" label="支付金额"/>
              <el-table-column prop="spec" label="规格"/>
              <el-table-column prop="specNo" label="编号"/>
              <el-table-column prop="date" label="操作">
                <template slot-scope="scope">
                  <el-button type="text" size="small" v-if="scope.row.commodityType == 10" @click="toLq(scope.row)">选择规格</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <div style="height: 30px">
        <el-button type="primary" size="small" style="float: right" @click="lq">确 认</el-button>
      </div>
    </el-dialog>
    <!--表单组件-->
    <el-dialog append-to-body :close-on-click-modal="false" :before-close="specEvent" :visible.sync="visible"
               :title="title" width="600px">
      <div style="margin-top: -10px">
        <el-input placeholder="请输入规格" v-model="specName" class="input-with-select" size="small"
                  style="margin-top: -25px">
          <el-button slot="append" icon="el-icon-search" @click="sousuo"></el-button>
        </el-input>
        <el-table :data="spList" style="width: 100%" height="300" @row-click="addSku">
          <el-table-column prop="spec" label="规格"/>
          <el-table-column prop="cpStock" label="库存"/>
          <el-table-column prop="retailPrice" label="价格"/>
        </el-table>
      </div>
      <div style="margin-top: 8px;margin-left: 5px;display: flex;flex-direction: row;align-items: center">
        <span style="margin-right: 8px">编码:</span>
        <el-input v-model="specNo" size="mini" style="width: 300px"/>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import {getSwName,getToken} from "../../utils/auth";

  export default {
    name: "xq",
    data() {
      return {
        deptName: getSwName(),
        depositId: '',
        memberId: '',
        title: '',
        memberName: '',
        phone: '',
        sex: '',

        ditems: [],

        specNo: '',
        specName: '',
        spList: [],
        goodsList: [],
        visible: false,
        skuItem: {}
      };
    },
    async created() {
      this.depositId = this.id
      this.memberId = this.mbid
      this.getDepositItem()
    },
    props: {
      xqVisible: Boolean,
      id: String,
      mbid: String,
    },
    methods: {
      closeEvent() {
        this.$emit("closeXQ")
      },
      specEvent() {
        this.visible = false
        this.specNo = ''
        this.skuItem = {}
      },
      toLq(item) {
        this.visible = true
        this.addSpec(item)
      },

      addSpec(item) {
        this.skuItem = item
        this.spList = JSON.parse(JSON.stringify(item.skuSpecs))
        this.title = item.commodityName
        this.visible = true
      },

      sousuo() {
        var name = this.specName.replace(/\s+/g, "");
        let specs = []
        this.skuItem.skuSpecs.forEach(item => {
          var newStr = item.spec.replace(/\s+/g, "");
          let b = newStr.toUpperCase().match(name.toUpperCase())
          if (b) {
            specs.push(item)
          }
        })
        if (specs.length > 0) {
          this.spList = specs
        } else {
          this.spList = []
        }
      },
      //添加卖品
      addSku(row) {
        this.skuItem.specId = row.id
        this.skuItem.spec = row.spec
        this.skuItem.specNo = this.specNo
        this.specNo = ''
        this.visible = false
      },

      del(index) {
        this.goodsList.splice(index, 1)
      },

      async getDepositItem() {
        let res = await this.$get("/sw/getSwDepositItem", {depositId: this.depositId, memberId: this.memberId});
        this.memberName = res.memberName
        this.phone = res.phone
        this.sex = res.sex
        this.ditems = res.ditems
      },

      async lq() {
        if (this.ditems.length <= 0) {
          this.$message.error("没有添加领取的商品")
          return;
        }
        var is =false
        this.ditems.forEach(item=>{
          if (item.commodityType==10){
            if (!item.specId){
              is=true
            }
          }
          this.goodsList.push({
            depositItemId: item.depositItemId,
            orderInfoId: item.id,
            commodityName: item.commodityName,
            price: item.price,
            specId: item.specId,
            spec: item.spec,
            specNo: item.specNo,
          })
        })
        if (is){
          this.$message.error("有成品没有选择规格")
          return ;
        }
        let data = {
          goodsListStr: JSON.stringify(this.goodsList),
          depositId: this.depositId,
          token:getToken(),
        }
        let res = await this.$post("/sw/swLqDepositItem", data);
        if (res.code == 200) {
          this.closeEvent()
          this.$message.success(res.message);
        } else {
          this.$message.error(res.message);
        }
      },
    },
    components: {},
  };
</script>


<style lang="scss" scoped>

  .lqbt {
    border-left: #2E3191 solid 8px;
    padding-left: 5px;
    margin: 12px 0px;
  }

  .mbinfo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .bttt {
    width: 33.4%;
    background-color: #EFF3F9;
    text-align: center;
    height: 30px;
    line-height: 30px;
  }

  .bttt1 {
    width: 33.4%;
    text-align: center;
    height: 30px;
    line-height: 30px;
  }

  .goodsinfo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .gbttt {
    width: 25%;
    background-color: #EFF3F9;
    text-align: center;
    height: 30px;
    line-height: 30px;
  }

  .gbttt1 {
    width: 25%;
    text-align: center;
    height: 30px;
    line-height: 30px;
  }
</style>
