<template>
  <div>
    <el-dialog v-if="xqVisible" :close-on-click-modal="false" title="开单领取" width="85%" :visible.sync="xqVisible"
               :before-close="closeEvent">
      <div style="height: 600px;display: flex;flex-direction: row">
        <div style="width: 70%;">
          <div class="lqbt">客户信息</div>
          <div style="border: #dddddd solid 1px">
            <div class="mbinfo">
              <span class="bttt">姓名</span>
              <span class="bttt">手机</span>
              <span class="bttt">性别</span>
            </div>
            <div class="mbinfo" style="margin-bottom: 10px">
              <span class="bttt1">{{memberName}}</span>
              <span class="bttt1">{{phone}}</span>
              <span class="bttt1" v-if="sex==1">男</span>
              <span class="bttt1" v-if="sex==2">女</span>
            </div>
          </div>
          <div class="lqbt">商品信息</div>
          <div>
            <el-table
              :data="datas"
              @row-click="setRow"
              highlight-current-row
              border
              style="width: 100%">
              <el-table-column prop="commodityName" label="商品名称" width="180"/>
              <el-table-column prop="price" label="商品价格"/>
              <el-table-column prop="dj" label="定金"/>
              <el-table-column prop="wk" label="尾款">
                <template slot-scope="scope">
                  <el-input
                          size="mini"
                          v-model="scope.row.wk"
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="sdAch" label="业绩">
                <template slot-scope="scope">
                  <el-input
                    size="mini"
                    v-model="scope.row.sdAch"
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="specNo" label="员工">
                <template slot-scope="scope">
                  <div v-if="scope.row.commodityType==0">
                    <div v-if="scope.row.femp">{{scope.row.femp.empName}}</div>
                    <div v-if="scope.row.semp">{{scope.row.semp.empName}}</div>
                    <div v-if="scope.row.temp">{{scope.row.temp.empName}}</div>
                  </div>
                  <div v-if="scope.row.commodityType!=0">
                    <div v-for="item in scope.row.emps">
                      <span>{{item.empName}}</span>
                    </div>
                  </div>
                </template>
              </el-table-column>
<!--              <el-table-column prop="date" label="操作">-->
<!--                <template slot-scope="scope">-->
<!--                  <el-button type="text" size="small" v-if="scope.row.commodityType == 10" @click="toLq(scope.row)">-->
<!--                    选择规格-->
<!--                  </el-button>-->
<!--                </template>-->
<!--              </el-table-column>-->
            </el-table>
          </div>
        </div>
        <div style="width: 30%;margin-left: 25px">
          <div class="lqbt">员工</div>
          <div style="border: #dddddd solid 1px;height: 500px" v-if="commodityType">
            <div class="imt-emp" v-if="exist&&commodityType==0">
              <div class="imt-gw" v-if="fEmps.length>0">
                <span class="gw-title">{{fName}}</span>
                <div class="emp-h">
                  <div class="gw-emp" v-for="item in fEmps">
                    <div class="emp-st">
                      <span class="name" @click="setLEmp(item,1)" @dblclick="setDEmp(item,1)">{{item.nickName}}</span>
                      <div class="emp-st-btn">
                        <div class="leftActive" v-if="item.status==1" @click="setUnchecked(1)">
                          轮
                        </div>
                        <div class="rightActive" v-if="item.status==2" @click="setUnchecked(1)">
                          点
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="imt-gw" v-if="sEmps.length>0">
                <span class="gw-title">{{sName}}</span>
                <div class="emp-h">
                  <div class="gw-emp" v-for="item in sEmps">
                    <div class="emp-st">
                      <span class="name" @click="setLEmp(item,2)" @dblclick="setDEmp(item,2)">{{item.nickName}}</span>
                      <div class="emp-st-btn">
                        <div class="leftActive" v-if="item.status==1" @click="setUnchecked(2)">
                          轮
                        </div>
                        <div class="rightActive" v-if="item.status==2" @click="setUnchecked(2)">
                          点
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="imt-gw" v-if="tEmps.length>0">
                <span class="gw-title">{{tName}}</span>
                <div class="emp-h">
                  <div class="gw-emp" v-for="item in tEmps">
                    <div class="emp-st">
                      <span class="name" @click="setLEmp(item,3)" @dblclick="setDEmp(item,3)">{{item.nickName}}</span>
                      <div class="emp-st-btn">
                        <div class="leftActive" v-if="item.status==1" @click="setUnchecked(3)">
                          轮
                        </div>
                        <div class="rightActive" v-if="item.status==2" @click="setUnchecked(3)">
                          点
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="imt-emp" v-if="exist&&commodityType!=0">
              <div class="imt-gw" v-if="fEmps.length>0">
                <span class="gw-title">{{fName}}</span>
                <div class="emp-h">
                  <div class="gw-emp" v-for="item in fEmps">
                    <div class="emp-st" :class="{hui: item.check}" @click="setEmp(item,1)">
                      <span class="name">{{item.nickName}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="imt-gw" v-if="sEmps.length>0">
                <span class="gw-title">{{sName}}</span>
                <div class="emp-h">
                  <div class="gw-emp" v-for="item in sEmps">
                    <div class="emp-st" :class="{hui: item.check}" @click="setEmp(item,2)">
                      <span class="name" :class="{hui: item.check}">{{item.nickName}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="imt-gw" v-if="tEmps.length>0">
                <span class="gw-title">{{tName}}</span>
                <div class="emp-h">
                  <div class="gw-emp" v-for="item in tEmps">
                    <div class="emp-st" :class="{hui: item.check}" @click="setEmp(item,2)">
                      <span class="name">{{item.nickName}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="height: 50px">
        <div style="width: 50%;float: right;display: flex;flex-direction: row;">
          <span class="zj">总计：</span>
          <span class="total">￥{{ total }}</span>
          <button class="payment" @click="jisuan()">结 算</button>
        </div>
      </div>
    </el-dialog>
    <!--表单组件-->
    <el-dialog append-to-body :close-on-click-modal="false" :before-close="specEvent" :visible.sync="visible"
               :title="title" width="600px">
      <div style="margin-top: -10px">
        <el-input placeholder="请输入规格" v-model="specName" class="input-with-select" size="small"
                  style="margin-top: -25px">
          <el-button slot="append" icon="el-icon-search" @click="sousuo"></el-button>
        </el-input>
        <el-table :data="spList" style="width: 100%" height="300" @row-click="addSku">
          <el-table-column prop="spec" label="规格"/>
          <el-table-column prop="cpStock" label="库存"/>
          <el-table-column prop="retailPrice" label="价格"/>
        </el-table>
      </div>
      <div style="margin-top: 8px;margin-left: 5px;display: flex;flex-direction: row;align-items: center">
        <span style="margin-right: 8px">编码:</span>
        <el-input v-model="specNo" size="mini" style="width: 300px"/>
      </div>
    </el-dialog>
    <TabPayment
      v-if="payVisible"
      :payVisible="payVisible"
      :total="total.toString()"
      :member="member"
      :memberCard="memberCard"
      @closePay="closePay"
      @successPay="successPay"
      :data="data"
    />
  </div>
</template>

<script>
  import {getSwName, getToken} from "../../utils/auth";
  import TabPayment from "./payment";
  export default {
    name: "wk",
    components: {
      TabPayment
    },
    data() {
      return {
        deptName: getSwName(),
        depositId: '',
        memberId: '',
        title: '',
        memberName: '',
        phone: '',
        sex: '',

        datas: [],

        specNo: '',
        specName: '',
        spList: [],
        goodsList: [],
        visible: false,
        skuItem: {},

        xIndex: '',

        commodityType: '',
        exist: false,
        fEmps: [],
        sEmps: [],
        tEmps: [],
        fName: '',
        sName: '',
        tName: '',
        total: 0,

        member: {},
        memberCard: {},

        payVisible: false,
        data:'',
        orderId:'',
      };
    },
    async created() {
      this.depositId = this.id
      this.memberId = this.mbid
      this.getMemberCard()
      this.getEmpList();
      this.getDepositItem()
    },
    props: {
      xqVisible: Boolean,
      id: String,
      mbid: String,
    },
    methods: {
      //获取用户会员卡
      async getMemberCard(){
        let res = await this.$get("/sw/getSwMemberCard", {memberId: this.memberId});
        if (res.mc){
          this.memberCard=res.mc
        }
      },
      //获取员工列表
      async getEmpList() {
        let res = await this.$get("/sw/getSwEmpList", {token: getToken()});
        this.fEmps = res.fEmps;
        this.sEmps = res.sEmps;
        this.tEmps = res.tEmps;
        this.fName = res.fName;
        this.sName = res.sName;
        this.tName = res.tName;
        this.exist = res.exist;
      },
      async getDepositItem() {
        let res = await this.$get("/sw/getSwWkDepositItem", {
          depositId: this.depositId,
          memberId: this.memberId,
          token: getToken()
        });
        this.orderId = res.orderId
        this.memberName = res.memberName
        this.phone = res.phone
        this.sex = res.sex
        this.datas = res.ditems
        if (this.datas.length > 0) {
          this.datas.forEach(item => {
            this.total = parseFloat(this.total) + parseFloat(item.wk)
            if (item.commodityType == 0) {
              item.femp = {}
              item.semp = {}
              item.temp = {}
            }
          })
        }
      },
      closeEvent() {
        this.$emit("closeWk")
      },
      closePay(){
        this.payVisible=false
        this.$emit("closeWk")
      },
      successPay(){
        this.payVisible=false
        this.$emit("closeWk")
      },
      specEvent() {
        this.visible = false
        this.specNo = ''
        this.skuItem = {}
      },
      toLq(item) {
        this.visible = true
        this.addSpec(item)
      },

      addSpec(item) {
        this.skuItem = item
        this.spList = JSON.parse(JSON.stringify(item.skuSpecs))
        this.title = item.commodityName
        this.visible = true
      },

      sousuo() {
        var name = this.specName.replace(/\s+/g, "");
        let specs = []
        this.skuItem.skuSpecs.forEach(item => {
          var newStr = item.spec.replace(/\s+/g, "");
          let b = newStr.toUpperCase().match(name.toUpperCase())
          if (b) {
            specs.push(item)
          }
        })
        if (specs.length > 0) {
          this.spList = specs
        } else {
          this.spList = []
        }
      },
      //添加卖品
      addSku(row) {
        this.skuItem.specId = row.id
        this.skuItem.spec = row.spec
        this.skuItem.specNo = this.specNo
        this.specNo = ''
        this.visible = false
      },

      del(index) {
        this.goodsList.splice(index, 1)
      },

      setRow(item) {
        this.dataIndex = this.datas.indexOf(item);
        this.commodityType = item.commodityType.toString()
        if (item.commodityType == 0) {
          this.setEmpStatus(item)
        } else {
          this.setSkuEmpStatus(item.emps)
        }
      },
      //设置员工
      async setLEmp(item, type,) {
        if (this.dataIndex == -1) {
          return;
        }
        if (this.datas.length > 0) {
          var is = 1;
          this.setUnchecked(type);
          if (type == 1) {
            this.datas[this.dataIndex].femp = {
              empId: item.id,
              empName: item.nickName,
              station: type,
              gradeId: item.gradeId,
              status: is
            }
          }
          if (type == 2) {
            this.datas[this.dataIndex].semp = {
              empId: item.id,
              empName: item.nickName,
              station: type,
              gradeId: item.gradeId,
              status: is
            }
          }
          if (type == 3) {
            this.datas[this.dataIndex].temp = {
              empId: item.id,
              empName: item.nickName,
              station: type,
              gradeId: item.gradeId,
              status: 1
            }
          }
          item.status = 1
        }
      },

      async setDEmp(item, type) {
        if (this.dataIndex == -1) {
          return;
        }
        if (this.datas.length > 0) {
          this.setUnchecked(type);
          if (type == 1) {
            this.datas[this.dataIndex].femp = {
              empId: item.id,
              empName: item.nickName,
              station: type,
              gradeId: item.gradeId,
              status: 2
            }
          }
          if (type == 2) {
            this.datas[this.dataIndex].semp = {
              empId: item.id,
              empName: item.nickName,
              station: type,
              gradeId: item.gradeId,
              status: 2
            }
          }
          if (type == 3) {
            this.datas[this.dataIndex].temp = {
              empId: item.id,
              empName: item.nickName,
              station: type,
              gradeId: item.gradeId,
              status: 2
            }
          }
          item.status = 2
        }
      },

      // 将选中的人状态更改为没选中
      setUnchecked(type) {
        if (type == 1) {
          this.changeEmpFalse(this.fEmps);
          this.datas[this.dataIndex].femp = {}
        }
        if (type == 2) {
          this.changeEmpFalse(this.sEmps);
          this.datas[this.dataIndex].semp = {}
        }
        if (type == 3) {
          this.changeEmpFalse(this.tEmps)
          this.datas[this.dataIndex].temp = {}
        }
      },

      // 切换项目更改员工选中状态
      setEmpStatus(item) {
        this.changeEmpFalse(this.fEmps);
        this.changeEmpFalse(this.sEmps);
        this.changeEmpFalse(this.tEmps);
        if (item.femp.station == 1) {
          this.changeEmpTrue(this.fEmps, item.femp)
        }
        if (item.semp.station == 2) {
          this.changeEmpTrue(this.sEmps, item.semp)
        }
        if (item.temp.station == 3) {
          this.changeEmpTrue(this.tEmps, item.temp)
        }
      },

      //更改状态
      changeEmpTrue(emps, emp) {
        emps.forEach(item => {
          if (emp.empId == item.id) {
            item.status = emp.status
          } else {
            item.status = 0
          }
        })
      },

      //更改状态
      changeEmpFalse(emps) {
        emps.forEach(item => {
          item.status = 0
        })
      },
      //设置员工
      async setEmp(item, type) {
        if (this.dataIndex == -1) {
          return;
        }
        if (this.datas.length > 0) {
          var is = item.check;
          if (!is) {
            this.datas[this.dataIndex].emps.push({
              empId: item.id,
              empName: item.nickName,
              station: type,
            })
          } else {
            var emp = this.datas[this.dataIndex].emps.find(it => {
              if (it.empId == item.id) {
                return it;
              }
            })
            let index = this.datas[this.dataIndex].emps.indexOf(emp);
            this.datas[this.dataIndex].emps.splice(index, 1)
          }
          item.check = !is
        }
      },

      // 切换商品更改员工选中状态
      setSkuEmpStatus(emps) {
        this.changeSkuEmpFalse(this.fEmps);
        this.changeSkuEmpFalse(this.sEmps);
        this.changeSkuEmpFalse(this.tEmps);
        for (var i = 0; i < emps.length; i++) {
          let emp = emps[i];
          if (emp.station == 1) {
            this.changeSkuEmpTrue(this.fEmps, emp, true)
          }
          if (emp.station == 2) {
            this.changeSkuEmpTrue(this.sEmps, emp, true)
          }
          if (emp.station == 3) {
            this.changeSkuEmpTrue(this.tEmps, emp, true)
          }
        }
      },

      //更改状态
      changeSkuEmpTrue(emps, emp, check) {
        emps.forEach(item => {
          if (emp.empId == item.id) {
            item.check = check
          }
        })
      },
      //更改状态
      changeSkuEmpFalse(emps) {
        emps.forEach(item => {
          item.check = false
        })
      },

      async jisuan() {
        if (this.datas.length <= 0) {
          this.$message.error("没有任何商品")
          return;
        }
        let mcId = ''
        if (this.memberCard && this.memberCard.id) {
          mcId = this.memberCard.id
        }
        let data = {
          datas: JSON.stringify(this.datas),
          total: this.total,
          depositId: this.depositId,
          memberId: this.memberId,
          mcId: mcId,
          orderId: this.orderId,
          token: getToken(),
          payType: '',
          hh: '',
        }
        this.data = data
        this.payVisible = true;
      },
    },
  };
</script>


<style lang="scss" scoped>

  .lqbt {
    border-left: #2E3191 solid 8px;
    padding-left: 5px;
    margin: 12px 0px;
  }

  .mbinfo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .bttt {
    width: 33.4%;
    background-color: #EFF3F9;
    text-align: center;
    height: 30px;
    line-height: 30px;
  }

  .bttt1 {
    width: 33.4%;
    text-align: center;
    height: 30px;
    line-height: 30px;
  }

  .goodsinfo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .gbttt {
    width: 25%;
    background-color: #EFF3F9;
    text-align: center;
    height: 30px;
    line-height: 30px;
  }

  .gbttt1 {
    width: 25%;
    text-align: center;
    height: 30px;
    line-height: 30px;
    padding: 0 3px;
  }

  .imt-emp {
    height: 100%;
    display: flex;
    flex-direction: row;
  }

  .emp-h {
    overflow: hidden;
    overflow-y: scroll;
    height: calc(100% - 35px);
  }

  .imt-gw {
    width: 100%;
    border-left: #F2F2F2 solid 1px;
    border-right: #F2F2F2 solid 1px;
  }

  .gw-title {
    width: 100%;
    text-align: center;
    display: block;
    height: 26px;
    line-height: 26px;
    border-bottom: #F2F2F2 solid 1px;
    margin-bottom: 5px;
  }

  .gw-emp {
    display: flex;
    height: 26px;
    line-height: 26px;
    flex-direction: column;
    margin-bottom: 5px;
  }

  .gw-emp .name {
    width: 80%;
    margin-left: 12px;
  }

  .emp-st {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
  }

  .emp-st-btn {
    position: relative;
    width: 50px;
    height: 18px;
    line-height: 18px;
    border-radius: 9px;
    color: #c8c9cc;
    padding: 0 6px;
    box-sizing: border-box;
  }

  .g-between {
    display: flex;
    justify-content: space-between;
  }

  .emp-st-btn .lun {
    width: 20px;
    text-align: center;
    font-size: 13px;
    height: 20px;
    line-height: 20px;
    cursor: pointer;
    background-color: #EEEEEE;
  }

  .emp-st-btn .leftActive {
    position: absolute;
    width: 22px;
    height: 22px;
    background: #616eb0;
    line-height: 22px;
    text-align: center;
    color: #fff;
    font-weight: 500;
    transform: translateY(-50%);
    top: 50%;
    border-radius: 50%;
    transition: all 0.8s;
    box-shadow: 0px 2px 5px rgba(17, 17, 17, 0.2);
    cursor: pointer;
  }

  .emp-st-btn .rightActive {
    position: absolute;
    width: 22px;
    height: 22px;
    background: #616eb0;
    line-height: 22px;
    text-align: center;
    color: #fff;
    font-weight: 500;
    transform: translateY(-50%);
    top: 50%;
    border-radius: 10px;
    transition: all 0.8s;
    box-shadow: 0px 2px 5px rgba(17, 17, 17, 0.2);
    cursor: pointer;
  }

  .emp-st {
    cursor: pointer;
  }

  .gw-emp .name {
    width: 90%;
    margin-left: 5px;
  }

  .gw-emp .hui {
    width: 90%;
    margin-left: 5px;
    cursor: pointer;
    background-color: #DDDDDD;
  }

  .bp-nr {
    display: flex;
    flex-direction: column;
  }

  .bp-sp {
    width: 100%;
    border: #C4C4C4 solid 1px;
    margin: 1px 0px;
    border-radius: 3px;
  }

  ::v-deep .el-table__body tr.current-row > td {
    color: #000000;
    background: #EEE9E9 !important;
  }

  .cg-emp {
    height: 30%;
    display: flex;
    flex-direction: row;
    border-top: #F2F2F2 solid 5px;
  }

  .emp-h {
    overflow: hidden;
    overflow-y: scroll;
    height: calc(100% - 35px);
  }

  .cg-gw {
    width: 100%;
    border-right: #F2F2F2 solid 5px;
  }

  .gw-title {
    width: 100%;
    text-align: center;
    display: block;
    height: 26px;
    line-height: 26px;
    border-bottom: #F2F2F2 solid 1px;
    margin-bottom: 5px;
  }

  .gw-emp {
    display: flex;
    height: 26px;
    line-height: 26px;
    flex-direction: column;
    margin-bottom: 5px;
  }

  .gw-emp .name {
    width: 100%;
    cursor: pointer;
    padding-left: 8px;
  }

  .gw-emp .hui {
    width: 100%;
    cursor: pointer;
    background-color: #DDDDDD;
  }


  .payment {
    width: 25%;
    height: 60px;
    border: 0;
    background-color: #e82742;
    color: #fff;
    cursor: pointer;
  }

  .zj {
    width: 55%;
    line-height: 60px;
    text-align: right;
  }

  .total {
    width: 20%;
    line-height: 60px;
    color: red;
  }


</style>
